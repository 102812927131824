import styled from "styled-components";
import {theme} from "/helpers/theme";

export const ManageWrapper = styled.main`
  .lk__mail {
    margin-top: 30px;

    &.editable-filed {
      margin-top: 23px;
      margin-bottom: -7px;

    }
  }
  
  .manage-form {
    .ant-form-item:first-child {
      .lk__mail {
        margin-top: 9px;
      }
    }
  }

  /* -----------    ЛК (vs-manage)    ----------- */

  .lk__balance {
    align-items: baseline; }


  .lk__balance h5 {
    margin-right: 16px;
    
    &.small-margin {
      margin-right: 6px;
    }
  }
  
  .lk__balance-col {
    align-items: flex-end;
    /* justify-content: flex-end; */
    display: flex;
    flex-direction: column;
    //margin-top: 12px;
  }

  .free-days {
    display: flex;
  }

  .lk__balance .free-days {
    display: flex;
    align-items: baseline; }


  .lk__balance .free-days h5 {
    margin-right: 0; }

  .lk__balance .p2.not-first {
    margin-right: 0;
  }
  
  .lk__options-payed .p2 {
    min-width: initial; }

  .lk__mail + .lk__mail {
    margin-top: 30px; }

  .lk__mail-wp h5 {
    min-width: 100px;
  }
  .lk-email-input-block.wrong,
  .lk__mail-wp.wrong input {
    border-color: #EF6957;
    border-style: solid;
    border-width: 1px;
    color: #EF6957;
  }
  .lk__mail-wp.wrong h5  {
    color: #EF6957;
  }

  .lk__mail .p2 {
    position: relative; }
  .lk__mail .p2 .filler {
    visibility: hidden; }
  .lk__mail .p2 .input-name {
    position: absolute;
    left: 0; }

  .lk__option2-and-good-vacancies {
    margin-bottom: 35px; }

  .lk__option2-and-good-vacancies .checkbox-row {
    display: flex;
  }
  .lk__option2-and-good-vacancies .default-checkbox1 {
    margin-top: 13px; }


  .lk__mail,.lk__mail-wp {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
  }

  .lk__mail-wp {
    position: relative
  }

  .content-editable-input {
    display: flex;
    align-items: center;
    padding-left: 6px;
  }

  .lk__mail-wp .manage-email-input,
  .lk__mail-wp input,
  .lk__mail-wp .content-editable-input
  {
    /*position: absolute;*/
    /*top: -9px;*/
    /*left: -3px;*/
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    background: #ffffff;
    font-family: Montserrat,sans-serif;
    z-index: 1;

    max-width: 200px;
  }

  .lk__mail-wp input,
  .lk__mail-wp .content-editable-input
  {
    height: 38px;
    border: 1px solid #8A8A89;
  }

  .lk__mail .p2 {
    margin-right: 16px;
    white-space: nowrap
  }

  .lk__mail h5 {
    margin-right: 16px;
    word-break: break-word
  }

  .lk__mail a {
    display: block;
    line-height: 0;
    position: relative;
    top: 1px
  }

  .lk__mail a:hover svg path {
    fill: #EF6957
  }


  .manage-form {
    //margin-top: 30px;

    .ant-form-item {
      margin: 0 !important;
    }
  }

  .manage-input {
    height: 22px !important;
    margin-top: 0px !important;
    padding: 0 !important;
    //top: 0px !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    min-width: 100%;
    width: 100%;
    left: -4px !important;
    padding-left: 3px !important;
  }

  .lk__subscr-right.good-vacancies-container {
    position: relative; }


  .lk__subscr {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    //margin-top: 28px;
    margin-bottom: 32px;
    max-width: 908px
  }

  .lk__subscr .p2 {
    margin-right: 26px;
    margin-top: 0;
  }

  .lk__subscr-left {
    width: 278px
  }

  .lk__subscr-right {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    //margin-left: 12px;
  }

  .lk__subscr .btn {
    height: 60px;
    width: 100%;
    font-size: 20px;
    line-height: 24px
  }

  .lk__subscr .p3 {
    margin-top: 10px;
    color: #7E8593
  }


  .lk__options {
    margin-top: 9px;
    //max-width: 950px
  }

  @media (max-width: 1600px) {
    .lk__options {
      margin-top: 24px;
    }
  }

  @media (min-width: 1600px) {
    .lk__options {
      &.lk__networking-container {
        margin-left: 24px;
      }
    }
  }

  .lk__options-left,.lk__options-right {
    //max-width: 365px
  }

  .lk__options-title span {
    color: #8A8A89
  }

  .lk__options .p3 {
    margin-top: 13px;
    //min-height: 66px;
    margin-bottom: 24px;
    opacity: .8;
    
    &.lk__networking-toggle {
      margin-top: 30px;
    }
  }

  .lk__options-wp {
    //margin-top: 36px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .lk__options .btn,.lk__options .btn2 {
    height: 64px
  }

  .lk__options .btn {
    max-width: 360px
  }

  .lk__options .btn,.lk__options .btn2 {
    width: 100%;
    font-size: 20px;
    line-height: 24px
  }

  .lk__options .btn2 {
    max-width: 349px;
    padding-left: 15px;
    padding-right: 15px
  }

  .lk__options-payed {
    margin-top: 24px
  }

  .lk__options-payed,.lk__options-payed .p2 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
  }

  .lk__options-payed .p2 {
    margin-right: 19px;
    min-width: 288px
  }

  .lk__options-payed .p2 svg {
    margin-right: 16px;
    margin-top: -4px
  }

  .lk__options-payed a {
    position: relative
  }

  .lk__options-payed a svg path {
    transition: .25s
  }

  .lk__placeholder-value {
    color: ${theme.colors.darkGray};
    font-style: italic;
    margin-right: 16px;
    font-weight: 400;
  }

  .lk__options-payed a h5 {
    position: absolute;
    top: -78px;
    left: -108px;
    padding: 20px;
    background: #F5F7FB;
    box-shadow: 0 1.23581px 6.6375px rgba(11,12,28,.09);
    width: 235px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: .25s
  }

  .lk__options-payed a h5:before {
    position: absolute;
    content: "";
    transform: rotate(45deg);
    background: #F5F7FB;
    width: 15px;
    height: 15px;
    bottom: -9px;
    left: 47%;
    z-index: 1
  }

  .lk__options-wp .lk__subscr-right {
    align-items: center;
    margin-left: 0;
  }
  
  .lk__options-wp .lk__subscr-right .p2 {
    margin-right: 12px;
  }


  .lk__balance {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    //margin-top: 18px
  }

  .lk__balance .p2 {
    margin-right: 16px;

    &.small-margin {
      margin-right: 6px;
    }
    
    &.no-margin {
      margin: 0
    }
  }


  .lk__score {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-width: 263px;
    margin-top: 8px;
  }

  .lk__score .p2 {
    margin-right: 16px
  }

  .lk__options-error-top {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-top: -3px
  }

  .lk__options-error-top svg {
    margin-right: 10px
  }

  .lk__options-error .p2,.lk__options-error .p3 {
    color: #EF6957;
    opacity: 1
  }

  .lk__options-error .p3 {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 33px
  }

  @media (min-width: 1441px) {
    .lk__options-error .p3 {
      width:552px
    }
  }

  @media (max-width: 1440px) {
    .lk__options .btn,.lk__options .btn2,.lk__parametr-left .btn, .lk__parametr-left .btn2,.lk__subscr .btn {
      height:56px
    }
  }


  @media (max-width: 1140px) {
    .lk__option2-and-good-vacancies .default-checkbox1 {
      margin-top: 7px; }}


  @media (max-width: 1140px) {
    .lk__score {
      margin-top: 22px
    }
  }

  @media (min-width: 991px) {
    .lk__options-payed a:hover svg path {
      fill:#4985DF
    }

    .lk__options-payed a:hover h5 {
      opacity: 1;
      visibility: visible
    }
  }

  @media (max-width: 990px) {
    .lk__mail-wp input,
    .lk__mail-wp .content-editable-input
    {
      font-size:14px;
      line-height: 20px;
      height: 34px;
      max-width: 150px;
    }

    .manage-input {
      font-size: 14px !important;
    }
  }
  
  @media (max-width: 990px) {
    .lk__score {
      margin-top: 12px
    }

    .lk__score .p2 {
      font-size: 14px;
      line-height: 20px;
      margin-right: 8px
    }

    .lk__score h5 {
      font-size: 16px;
      line-height: 20px
    }

    .lk__balance {
      //margin-top: 32px
    }

    .lk__balance .p2 {
      font-size: 14px;
      line-height: 20px;
      margin-right: 8px;

      &.small-margin {
        margin-right: 6px;
      }
    }

    .lk__balance h5 {
      font-size: 16px;
      line-height: 20px
    }

    .lk__subscr {
      //margin-top: 20px
    }

    .lk__subscr-right, .manage-form {
      .p2 {
        font-size: 14px;
      }
    }

    .lk__subscr .btn {
      height: 48px;
      font-size: 14px;
      line-height: 16px
    }

    .lk__mail {
      margin-top: 32px
    }

    .lk__mail a {
      top: auto
    }

    .lk__mail .p2 {
      margin-right: 8px
    }

    .lk__options {
      margin-top: 40px
    }

    .lk__options-left {
      margin-right: 20px
    }

    .lk__options-wp {
      margin-top: 26px
    }

    .lk__options .p3 {
      //min-height: 40px;
      margin-top: 12px;
      margin-bottom: 24px
    }

    .lk__options .btn,.lk__options .btn2 {
      font-size: 14px;
      line-height: 16px;
      height: 48px;
      max-width: 360px
    }

    .lk__options-payed {
      margin-top: 20px
    }

    .lk__options-payed svg {
      width: 21px;
      height: 16px;
      margin-right: 12px
    }

    .lk__options-payed a {
      font-size: 14px;
      line-height: 16px
    }

    .lk__options-payed .p2 {
      min-width: 0;
      white-space: nowrap
    }
  }

  @media (max-width: 990px) {
    .lk__balance h5 {
      margin-right: 8px;
      
      &.small-margin {
        margin-right: 6px;
      }
    }
  }

  @media (max-width: 990px) {
    .lk__mail + .lk__mail {
      margin-top: 16px; }}

  @media (max-width: 990px) {
    .lk__option2-and-good-vacancies {
      margin-bottom: 24px; }}

  
  @media (max-width: 769px) {
    .lk__balance-wrapper {
      margin-top: 12px;
    }
    
    .lk__balance-col {
      align-items: flex-start;
      /* justify-content: flex-end; */
      display: flex;
      flex-direction: column;
      //margin-top: 12px;
    }
  }
  
  @media (max-width: 760px) {
    .lk__mail {
      &.editable-filed {
        margin-top: 25px;
        margin-bottom: -7px;
      }
    }
    
    .lk__mail-wp input.manage-input,
    .lk__mail-wp .content-editable-input.manage-input
    {
      max-height: 34px;
      min-height: 34px;
      height: 34px;
      border: 1px solid #8A8A89;
    }

    .lk__mail-wp input.ant-select-selection-search-input,
    .lk__mail-wp .content-editable-input {
      max-height: 34px;
      min-height: 34px;
    }


    .lk__options-wp {
      display:block
    }

    .lk__options-right {
      margin-top: 30px
    }

    .lk__options-left,.lk__options-right {
      max-width: 480px;
      margin-right: 0
    }

    .lk__options .p3 {
      min-height: 0;
      font-size: 14px;
      line-height: 20px
    }

    .lk__subscr {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
    }

    .lk__subscr .btn {
      margin-right: 15px
    }

    .lk__subscr .p2 {
      margin-right: 16px
    }
  }

  @media (max-width: 760px) {
    .lk__balance {
      margin-top: 8px;
    }
  }


  @media (max-width: 660px) {
    .manage-form {
      margin-top: 0;
    }
    
    .lk__subscr {
      flex-wrap: wrap;
      flex-direction: column;
    }

    .lk__subscr-right {
      //margin-top: 24px;
      margin-left: 0;
      align-items: center;
      padding-bottom: 0;
      
      .p2 {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 575px) {
    .lk__subscr-left {
      width:280px
    }

    .lk__subscr .btn {
      margin-right: 0
    }

    .lk__subscr .p3 {
      margin-top: 12px
    }

    .lk__subscr .p2,.lk__subscr .p3 {
      font-size: 14px;
      line-height: 20px
    }

    .lk__mail {
      flex-wrap: wrap;
      align-items: center;
    }

    .lk__mail .p2 {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px
    }

    .lk__mail h5, .lk__mail .lk__label-block {
      line-height: 20px;
      margin-bottom: 0
    }

    .lk__mail h5, .lk__placeholder-value {
      font-size: 16px;
      margin-right: 19px
    }

    .lk__mail a {
      //top: -5px
    }

    .lk__options {
      margin-top: 30px
    }

    .lk__loader-container {
      top: 0
    }
  }
  
  @media (max-width: 575px) {
    .lk__mail-wp input,
    .lk__mail-wp .content-editable-input {
      font-size:16px;
      line-height: 20px
    }
  }


  @media (max-width: 360px) {
    .lk__balance .free-days {
      margin-top: 7px; }}

  @media (max-width: 360px) {
    .lk__balance {
      flex-wrap: wrap; }}
`;

export const ManageTabsWrapper = styled.div`
  
  .lk__tabs {
    margin-top: 45px;
    margin-bottom: 80px;
  }
  
  .ant-tabs-tab {
    padding-left: 0!important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab:hover,
  .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
    color: #ff4d4f;
    text-shadow: 0 0 0.25px #ff4d4f;
  }

  .ant-tabs-ink-bar {
    background: #ff4d4f;
  }

  @media (max-width: 760px) {
    .lk__tabs {
      margin-top: 25px;
      margin-bottom: 40px;
    }
  }
  
`;

export const ManageLkWrapper = styled.div`
  .lk__top {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 84px
  }
  
  .lk__score-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .lk__balance-wrapper {
    display: flex;
    align-items: center;

    .ant-row {
      white-space: nowrap;
      flex-wrap: nowrap;
      align-items: center;
    }
    
    .lk__balance {
      //margin-right: 12px;
    }
  }

  .auto-transactions-wrapper-header {
    display: flex;
    align-items: center;
    margin-top: 16px;
    
    .lk__auto-transaction-title {
      margin-right: 12px;
    }
    
    .btn, .btn2 {
      height: 32px;
      padding: 8px 15px 7px;
      font-size: 14px;
      max-width: 140px;
      min-width: 100px;
      width: 100%
    }
  }

  @media (max-width: 990px) {
    .auto-transactions-wrapper {
      .btn, .btn2 {
        height: 32px;
        padding: 8px 12px 7px;
        font-size: 14px;
      }
    }
  }
  
  .circle-balance-button {
    border-radius: 50%;
    background-color: rgb(239, 105, 87);
    padding: 6px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;

    .anticon {
      cursor: pointer;
    }
  }

  .lk__top h1 {
    margin-right: 15px;
    max-width: 1000px;
    font-size: 36px!important;
  }
  
  @media (max-width: 1140px) {
    .lk__top {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-align: initial;
      align-items: initial
    }
  }

  @media (max-width: 990px) {
    .lk__top {
      margin-top: 36px
    }
  }

  .custom-dropdown-container {
    top: 37px;
  }
  
  input:not(.ant-radio-input), .ant-select-selector {
    min-height: 38px;
    max-height: 38px;
  }
  
  .ant-select-selector {
    margin: 0!important;
    padding: 0 12px!important;
    min-width: 210px;
  }

  .ant-select-selection-search-input {
    margin-top: 0px!important;
  }
  
  .show-search-input .ant-select-selection-search-input {
    background-color: white!important;
  }
  
  .ant-select-selection-search-input {
    top: 0;
    left: 1px;
    background-color: transparent;
    min-width: 120px;
    min-height: 36px;
    max-height: 36px;
  }
  
  .ant-select-selection-placeholder {
    padding: 4px 0!important;
  }

  .ant-select-selection-item {
    padding-top: 3px!important;
  }

  @media (max-width: 990px) {
    .input:not(.ant-radio-input), .ant-select-selector  {
      font-size: 14px;
      line-height: 20px;
      min-height: 34px;
      max-height: 34px;
      
      
    }

    .ant-select-selection-search-input {
      font-size: 14px;
      line-height: 20px;
      max-height: 31px;
      height: 31px !important;
      min-height: 31px;
      left: 1px;
    }
    
    .ant-select-selection-item {
      padding-top: 1px!important;
    }

    .custom-dropdown-container {
      top: 33px;
    }
  }

  .auto-transactions-wrapper {
    .btn, .btn2 {
      height: 38px;
      padding: 8px 20px 7px;
      font-size: 16px;
      max-width: 300px;
      width: 100%
    }
  }

  @media (max-width: 990px) {
    .auto-transactions-wrapper {
      .btn, .btn2 {
        height: 32px;
        padding: 8px 12px 7px;
        font-size: 14px;
        width: 100%
      }
    }
  }

`;

export const ManageLkParameter = styled.div`
  &.lk__parametr {
    //margin-top: 80px;
    padding-top: 18px;
    //padding-bottom: 80px;
    //margin-bottom: 88px;
    //background: #F5F7FB
  }

  .lk__parametr-wp {
    display: -ms-flexbox;
    display: flex
  }

  #subscription-form-loader .lk-loader-content {
    margin-right: 0;
  }
  
  .ant-input-number {
    border: 1px solid transparent !important;
    box-shadow: none;
  }

  .lk__range-picker {
    border: 1px solid transparent;
    box-shadow: none;
  }
  
  .ant-form-item-control-input {
    min-height: auto!important;
  }

  .ant-form-item-has-error .ant-input-number,
  .ant-form-item-has-error .lk__range-picker {
    border-color: #ff4d4f !important;
  }

  input, textarea, .ant-select-selector {
    border: 1px solid transparent !important;
  }

  .ant-form-item-control-input-content {
    border: 1px solid #8a8a89 !important;
  }

  .checkbox-input-in-position .ant-form-item-control-input-content {
    border: none!important;
  }

  .ant-input:hover, .ant-input:focus,.ant-select-selector:hover {
    border: 1px solid transparent !important;
  }

  .ant-divider-horizontal.ant-divider-with-text {
    //margin-top: 42px;
    //margin-bottom: 24px;
  }

  .ant-picker-range .ant-picker-clear {
    right: 20px;
  }

  .ant-picker-active-bar {
    background-color: ${theme.colors.orange};
  }

  .ant-input-number-handler:hover .ant-input-number-handler-up-inner,
  .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
    color: ${theme.colors.orange};
  }

  .ant-collapse-item .site-collapse-custom-panel {
    width: 100%;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: flex;
    align-items: center;
    padding: 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header h5 {
    //flex: 1;
    margin-right: 12px;
  }

  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #8A8A89;
    cursor: pointer
  }
  
  .ant-form-item-control-input-content input {
    margin-top: 0!important;
  }

  .input-block {
    margin-bottom: 24px;
    //margin-top: auto;
  }
  
  .lk__range-picker-wrapper {
    margin-top: 38px;
  }
  
  .input-block.wrong .simple-select,
  .input-block.wrong input,
  .simple-select.wrong .simple-select,
  .simple-select.wrong input {
    border-color: #EF6957
  }

  .simple-select, input {
    border-color: transparent
  }
  
  .lk__parametr-title {
    margin-bottom: 42px;
  }
  
  .lk__parametr-prev .ant-form-item-explain-error {
    visibility: hidden;
    display: none;
  }

  .lk__range-picker-wrapper.ant-form-item-with-help {
    margin-bottom: 24px;
  }
  
  .lk__parametr-prev .lk__range-picker-wrapper .ant-form-item-explain-error {
    visibility: visible;
    display: block;
  }

  .profile-positions-btn-cancel {
    margin-right: 28px;
  }

  .lk__parametr-left {
    /*max-width: 563px*/
    max-width: 100%;
    min-width: 100%;
  }

  .lk__parametr-left .lk__position-title {
    margin-bottom: 26px;
    display: flex;
  }

  .lk__parametr-left .lk__position-title h5 {
    margin-right: 24px;
  }

  .lk__parametr-left .lk__position-list {
    display: flex;
    flex-wrap: wrap;
    /*flex-direction: column;*/
  }

  .lk__parametr-left .lk__position-list span {
    margin-right: 12px;
  }
  .lk__parametr-left .lk__position-list span:last-child {
    margin-bottom: 0;
  }

  .lk__parametr-left .lk__position-list .lk__mail-change {
    margin-top: 3px;
    margin-left: 5px;
  }

  .lk__position_button_icon {
    cursor: pointer;
    font-size: 20px;
    color: #8A8A89; 
    margin-right: 12px
  }
  .lk__disabled-button {
    opacity: 0.7;
    cursor: auto;
  }

  .lk__parametr-left .btn,
  .lk__parametr-left .btn2 {
    height: 64px;
    font-size: 20px;
    line-height: 24px;
    margin-top: 35px;
    width: 194px
  }

  .lk__parametr-left .btn.lk__position-changes {
    width: auto;
  }

  .lk__parametr-left .default-checkbox {
    margin-top: 30px
  }
  
  .lk__position-divider.disabled-button {
    opacity: 0.7;
    cursor: auto;
  }
  
  .lk__parametr-form {
    display: flex;
    &.lk__form-column {
      flex-direction: column;
      
      &.lk__position-without-break {
        margin-bottom: 32px;
      }
    }
  }


  .lk__parametr-form .lk__range-picker {
    padding: 0 20px 0 0;
    width: 100%;
  }
  
  .lk__parametr-form i {
    font-style: normal;
    font-size: 21px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: ${theme.colors.gray};
    display: -moz-inline-box;
    display: inline-flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    position: relative;
    right: -10px;
    cursor: pointer;
  }

  .lk__parametr-edit {
    position: relative;
    
    .lk__position-title {

      max-width: 563px;
      min-width: 563px;
    }
  }
  
  .lk__position_form_items {
    flex: 1; 
    max-width: 563px; 
    min-width: 563px
  }

  .lk__parametr-buttons-wrapper {
    display: flex;
    margin-bottom: 46px;
  }

  .lk__checkbox-label {
    font-size: 18px;
    margin-left: 12px;
  }

  .lk__salary-value {
    width: 100%;
  }

  .salary-block {
    //display: flex;
    //align-items: center;
    position: relative;
    
    .ant-form-item-label {
      overflow: unset;
    }
  }

  .salary-clear-button {
    position: absolute;
    right: 30px;
    cursor: pointer;
    color: #00000040;
    z-index: 2;

    top: 25px;
    opacity: 0;
    transition: opacity 0.24s linear 0.1s;
  }

  
  .salary-block:hover .salary-clear-button {
    opacity: 1;
  }
  
  .lk__parametr-curr {
    //margin-top: 23px
  }


  .lk__parametr-prev .lk__parametr-buttons-wrapper .btn2,
  .lk__parametr-prev .lk__parametr-buttons-wrapper .btn {
    margin-top: 0;
  }

  .lk__parametr-prev-panel {
    margin-top: 53px
  }

  .lk__parametr-prev {
    margin-top: 42px
  }

  .lk__parametr-form:first-child .lk__parametr-prev{
    margin-top: 0;
    /*23px*/
  }
  
  .lk__parametr-prev .clear-pos {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 50px;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-top: 37px;
    color: #8A8A89;
    transition: color .25s
  }

  .lk__parametr-prev .clear-pos svg {
    margin-right: 17px
  }

  .lk__parametr-subindustry,
  .lk__parametr-subfunction {
    padding-left: 28px;
    
    .ant-select-clear {
      right: 41px;
      font-size: 14px;
      top: 53%;
    }
  }
  
  .simple-select__item.not-selected {
    color: #8A8A89 !important;
  }

  .lk__not-selected-subind .ant-select-selection-item,
  .lk__not-selected-subfunc .ant-select-selection-item {
    color: #8A8A89 !important;
  }


  
  @media (min-width: 1140px) {
    
    .lk__parametr-left .default-checkbox__text {
      font-size:16px;
      line-height: 24px;
      white-space: nowrap
    }
  }
  
  @media (max-width: 990px) {

    .lk__parametr-subindustry,
    .lk__parametr-subfunction {

      .ant-select-clear {
        right: 31px;
        font-size: 12px;
        top: 50%;
      }
    }
    
    .lk__range-picker-wrapper .ant-form-item-explain-error {
      font-size: 12px;
    }

    .lk__range-picker-wrapper {
      margin-top: 14px;
    }
    
    .lk__position_form_items {
      flex: 1;
      max-width: 563px;
      min-width: 563px;
    }
    
    .lk__range-picker-wrapper.ant-form-item-with-help {
      margin-bottom: 16px;
    }
    
    .input-block {
      margin-bottom: 15px;
    }
    
    .lk__parametr-prev .clear-pos:hover {
      color:#EF6957
    }

    .lk__parametr-prev-panel {
      margin-top: 32px;
    }

    .lk__parametr-buttons-wrapper {
      margin-bottom: 28px;
    }

    .lk__parametr-form i {
      width: 18px;
      height: 18px;
      font-size: 14px;
    }

    .lk__checkbox-label {
      font-size: 12px;
    }
    
    .profile-positions-btn-cancel {
      margin-right: 12px;
    }

    .lk__parametr-form .lk__range-picker {
      padding: 0 10px 0 0;
    }
    
    .ant-picker-range .ant-picker-clear {
      right: 10px;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
      font-size: 20px;
    }

    &.lk__parametr .btn,
    &.lk__parametr .btn2 {
      height: 48px;
      font-size: 14px;
      line-height: 16px;
      margin-top: 25px
    }

    &.lk__parametr .btn.lk__position-changes {
      margin-top: 32px
    }

    .lk__parametr-prev .clear-pos {
      font-size: 14px;
      line-height: 16px;
      margin-top: 21px;
      margin-bottom: 20px
    }

    .lk__parametr-prev .clear-pos svg {
      width: 12px;
      height: 14px;
      margin-right: 10px
    }

    .salary-clear-button {
      opacity: 1;
      top: 14px;
      right: 29px;
      font-size: 12px;
    }
    
  }



  @media (max-width: 760px) {
    .lk__parametr-title {
      margin-bottom: 16px;
    }
    
    .lk__position_form_items {
      flex: 1;
      max-width: 537px;
      min-width: 537px;
    }
  }

  @media (max-width: 760px) {
    &.lk__parametr {
      padding-top: 8px;
    }
  }
  

  @media (max-width: 575px)  {
    .ant-divider-horizontal.ant-divider-with-text {
      //margin-top: 29px;
      //margin-bottom: 12px;
    }

    .lk__position_form_items {
      flex: 1;
      max-width: 100%;
      min-width: 100%
    }
    
    .lk__parametr-title {
      margin-bottom: 29px;
    }
    
    .lk__parametr-edit {
      max-width: 100%;
      min-width: 100%;
      position: relative;
    }

    &.lk__parametr {
      padding-top: 8px;
      padding-bottom: 0;
      margin-bottom: 0
    }

    .lk__parametr-left {
      margin-bottom: 28px;
      max-width: 100%
    }

    .lk__parametr-left .lk__position-title {
      margin-bottom: 17px;
    }

    .lk__parametr-left .default-checkbox {
      margin-top: -5px
    }

    .lk__parametr-prev {
      margin-top: 29px
    }

    .lk__parametr .btn,
    .lk__parametr .btn2 {
      margin-top: 15px;
      width: 100%
    }
  }
`;

export const ManageUnsubscribeButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  background: ${theme.colors.gray};
  padding: 16px 10px;
  align-items: center;
  margin-top: 24px;
  
  .unsubscribe-text {
    flex: 1;
    font-size: 16px;
    line-height: 22px;
    margin-right: 16px;
  }
  
  .btn {
    height: 40px;
    font-size: 16px;
  }

  @media(max-width: 990px) {
    .btn {
      height: 40px;
      font-size: 14px;
    }
  }

  @media(max-width: 760px) {
    flex-direction: column;
    align-items: flex-start;

    .unsubscribe-text {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  @media(max-width: 475px) {
    flex-direction: column;
    
    .btn {
      height: 40px;
    }

    .unsubscribe-text {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const ManageVerificateEmailWrapper = styled.div`

  .ant-form-item-with-help {
    margin-bottom: 24px;
  }
  
  .ant-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }

  .verificate-countdown {
    width: 315px!important;
    margin-bottom: 36px;
    text-align: center;
  }
  
  .verificate-code-input {
    border: 0!important;
    border-bottom: 1px solid #8A8A89 !important;
    font-size: 38px !important;
    padding-left: 10px !important;
    width: 184px!important;
    padding-right: 10px !important;
    
    &.ant-form-item-with-help {
      margin-bottom: 24px;
    }
  }

  @media(max-width: 990px) {
    .ant-form-item-with-help {
      margin-bottom: 16px;
    }
  }
`;

export const ManageVerificateEmailButtons = styled.div`
  display: flex; 
  justify-content: center; 
  flex-wrap: wrap;
  width: 100%;
  
  .left-column {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  .right-column {
    flex: 1;
    display: flex;
    justify-content: start;
    margin-left: 24px;
  }
  
  .btn, .btn2 {
    padding: 10px 30px 9px;
    min-width: 188px;
  }

  @media(max-width: 575px) {
    .left-column {
      justify-content: start;
    }
    .right-column {
      margin-left: 12px;
      justify-content: end;
    }
    .btn {
      max-width: auto;
      min-width: 159px;
      padding: 10px 12px 9px;
    }

    .btn2 {
      min-width: 159px;
      padding: 10px 12px 9px;
    }
  }

  @media (max-width: 475px){
    display: block;
    .btn, .btn2 {
      max-width: 100%;
      min-width: 100%;
      padding: 10px 8px 9px;
    }
    .right-column {
      margin-left: 0;
    }
    .btn2 {
      margin-top: 10px;
    }
  }

  @media (max-width: 390px){
    .btn, .btn2 {
      max-width: 100%;
      min-width: 100%;
    }
    .right-column {
      margin-left: 0;
      justify-content: start;
    }
  }
  
  .btn.vacancy-link {
    padding: 0
  }
  
  .vacancy-link a {
    color: white;
    padding: 10px 30px 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    
    &:hover {
      color: white;
    }

    @media(max-width: 575px) {
      padding: 10px 12px 9px;
    }

    @media (max-width: 475px){
      padding: 10px 10px 9px;
    }
  }
`;

export const ManageHidePositionWrapper = styled.div`
  margin-top: 24px;
  
  .ant-form-item-with-help {
    margin-bottom: 24px !important;
  }
`;

export const ManageHidePositionButtons = styled.div`
  display: flex; 
  justify-content: space-between; 
  flex-wrap: wrap;
  width: 50%;
  margin: 0 auto;

  @media (max-width: 990px) {
    width: 75%;
  }

  @media (max-width: 575px) {
    width: 100%;
  }
`;

export const ManageDisableRecurringButtons = styled(ManageHidePositionButtons)`
  margin-top: 24px;
`;

export const ManageTariffsWrapper = styled.div`{
  .ant-col {
    padding: 8px!important;
  }
}`

export const ManageTariffWrapper = styled.div`{
  border: 1px solid #F5F7FB;
  box-shadow: 0 67px 138px rgb(0 0 0 / 3%), 0 4.45557px 9.17714px rgb(0 0 0 / 7%);
  height: 100%;

  display: flex;
  flex-direction: column;

  .btn, .btn2 {
    height: 42px;
    padding: 10px 20px 9px;
    font-size: 16px;
  }

  .btn {
    margin-right: 16px;
  }

  .ant-btn.btn:hover, .ant-btn.btn:focus {
    color: #fff;
    background: #EF6957;
    border-color: #EF6957;
  }

  .btn:hover, .btn:focus,
  .btn2:hover, .btn2:focus {
    border-color: #EF6957;
  }

  .btn[disabled]:not(.profile-positions-btn),
  .btn[disabled]:not(.profile-positions-btn):focus,
  .btn[disabled]:not(.profile-positions-btn):hover {
    background: #F9F9F9;
    border-color: #F9F9F9;
    color: #8A8A89;
    cursor: not-allowed
  }

  .btn2[disabled], .btn2[disabled]:focus, .btn2[disabled]:hover {
    background: #F9F9F9;
    border-color: #F9F9F9;
    color: #8A8A89;
    cursor: not-allowed
  }

  .tariff-new-count {
    width: 70px;
    justify-content: center;
    margin-right: 4px;
    display: flex;
    font-weight: 500 !important;
    font-size: 18px !important;
  }

  .tariff-counter {
    position: absolute;
    box-shadow: 0 67px 138px rgb(0 0 0 / 3%), 0 4.45557px 9.17714px rgb(0 0 0 / 7%);
    top: -7px;
    background: #ab3f0c;
    color: #fff;
    padding: 4px 8px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -2%;
    border-radius: 14px;
    font-size: medium;

    &.residual-counter {
      background: #efcc57;
    }
  }

  .tariff-cost-in-add {
    text-align: center;
    margin-top: 12px;
    font-style: italic;

    display: flex;
    flex-direction: column;
    
    color: ${theme.colors.darkGray};
    
    .text-for-remainder {
      color: ${theme.colors.darkGray};
      margin-left: 4px
    }
  }

  .tariff-custom-input {
    width: 70px;
    justify-content: center;

    input:not(.ant-radio-input) {
      min-height: 32px;
      max-height: 32px;
      border: none !important;

      padding: 0 4px !important;
      text-align: center;
    }

    margin-right: 4px;
  }

  .tariff-paused-button-wrapper {
    height: 32px;
    margin-top: 12px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .tariff-paused-button,
  .tariff-paused-button:hover {
    color: #EF6957;
    padding: 0;
  }

  .tariff-header {
    position: relative;
    background: #F5F7FB;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 12px 12px;
    font-size: 20px;
    font-weight: bold;
  }

  &.active {
    border: 1px solid #EF6957;

    .tariff-header {
      background: #EF6957;
      color: white;
    }
  }

  &.residual {
    //border: 1px solid #EF6957;
    //
    //.tariff-header {
    //  background: #EF6957;
    //  color: white;
    //}
  }

  &.suspend {
    //border: 1px solid #efcc57;

    .tariff-header {
      //background: #efcc57;
      //color: white;
    }
  }

  .tariff-body {
    padding: 12px 24px 56px;
    flex: 1;

    .ant-list-split .ant-list-item {
      border: 0;

      .ant-list-item-meta-title {
        font-weight: normal;
      }
    }

    .ant-list-item-meta-avatar {
      margin-right: 14px;
      color: #EF6957
    }

    .tariff-mailings-days-wrapper {
      display: flex;
      flex-direction: column;
      max-width: 80%;
      text-align: center;
      margin: 0 auto;
      margin-top: 12px;
    }

    .tariff-mailings-days {
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      //padding: 0px 12px;

      input.ant-input {
        margin-top: 0 !important;
        padding: 0 !important;
        text-align: center;
        min-height: 28px;
        max-height: 28px;
      }

      .anticon {
        font-size: 22px;
        color: #EF6957
      }

      .tariff-icon-left,
      .tariff-icon-right {

      }

      .tariff-icon-left {
        margin-right: 12px;
      }

      .tariff-icon-right {
        margin-left: 12px;
      }
    }
  }

  .tariff-cost {
    margin-bottom: 18px;
    font-size: 20px;
  }

  .tariff-footer {
    padding: 12px 24px 10px;
  }

  .buttons-wrapper {
    .ant-btn:last-child {
      margin-bottom: 0;
    }
  }


  @media (max-width: 1444px) {
    .buttons-wrapper {
      flex-direction: column;

      .btn, .btn2 {
        height: 38px;
        padding: 8px 20px 7px;
        font-size: 16px;
        width: 100%
      }

      .btn {
        margin-bottom: 12px;
        
        &.without-margin-bottom {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 990px) {
    font-size: 14px;

    input, textarea, .ant-select-selector {
      font-size: 16px !important;
    }
  }
}`


export const ManageFavoriteVacanciesWrapper = styled.div`
  display: flex;
  position: relative;
  
  .dream-vacancy-wrapper {
    position: relative;
    width: 100%;
    display: none;
    
    .close-icon {
      font-size: 24px;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.7;
    }
    
    &.visible {
      display: block;
    }
  }

  .arrow-container {
    height: 100%;
    width: 50px;
    z-index: 100;
    position: absolute;
    top: 0;
  }

  .arrow-left {
    background: linear-gradient(90deg, #caccd785, #c1c1c100);
    left: 0
  }

  .arrow-right {
    background: linear-gradient(-90deg, #caccd785, #c1c1c100);
    right: 0;
  }


  .swiper-scrollbar {
    position: absolute;
    bottom: 0;
    height: 3px;
    border-radius: 2px;
    background-color: rgb(238, 238, 238);
    width: 100%;
  }

  .swiper-scrollbar-drag {
    background-color: rgb(181 181 181);
    height: 3px;
    border-radius: 2px;
  }

  .swiper-container {
    width: 100%;
    padding-bottom: 12px;
    position: relative;
  }

  .swiper-slide {
    max-width: 410px;
  }

  .podp {
    display: flex;
    align-items: center;
    line-height: 16px;
  }

  .favorite-vacancy {
    background: #F5F7FB;
    padding: 16px 24px 16px 16px;
    position: relative;
    flex: 1;
    max-width: 406px;
    display: flex;
    flex-direction: column;
    height: 100%;

    &.blur-text {
      filter: blur(4px);
    }
  }

  .favorite-vacancy-info {
    display: flex;
    flex-direction: column;
    flex: 1
  }

  .favorite-title {
    margin-bottom: 24px;
  }

  .favorite-delete {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
  }

  .city-and-date {
    display: flex;
    margin-bottom: 0px;
  }

  .go-to-path {
    margin-top: 12px;
  }

  .favorite-vacancy .btn {
    height: 40px;
    font-size: 16px;
    background: #fff;
    border: 1px solid #EF6957;
    color: #EF6957;
  }

  .favorite-vacancy .btn:hover,
  .favorite-vacancy .btn:focus,
  .favorite-vacancy .btn:active {
    background: #EF6957;
    color: #fff;
  }

  .vacancy-hidden-price-wrapper {
    span span {
      height: 22px;
      display: flex;
      align-items: center;
      transform: translateY(0);
    }
  }

  .vacancy-outdated {
    //position: absolute;
    //top: 0;
    //left: 0;
    height: 100%;
    width: 100%;

    z-index: 1;
    display: flex;
    align-items: center;
    padding: 30px;
    justify-content: center;
    text-align: center;
    background: #f5f6fba1;
    color: #484b4d;
    font-weight: 600;
    font-size: 22px;
    
    &.white-background {
      background: transparent;
    }
  }

  .anticon {
    font-size: 18px;
    cursor: pointer;
    color: #323232;
    
    &.arrow-icon {
      color: #EF6957;
    }
  }

  .anticon:hover {
    color: #EF6957;
  }

  @media (max-width: 990px) {
    .favorite-vacancy .btn.go-to-path {
      height: 40px;
      font-size: 14px;
      margin-top: 12px;
    }
  }
`
